import React from 'react'
import PostcodeCheckTool from '../Search'
// import Order from './Order'

export default function PostcodeCheck() {
  return <PostcodeCheckTool />
}

// export default function PostcodeCheck() {
//   return (
//     <Order
//       banner={{
//         description: `
//               <h1><strong>Doe hier de postcodecheck</strong></h1>
//               Zakelijk glasvezel internet voor een vast maandelijks bedrag. Doe de postcodecheck en bekijk wat er mogelijk is op uw locatie.
//             `,
//       }}
//       messages={{
//         quotationSend: `
//               <h2><span style="color: #3dbc4a;">Uw offerteaanvraag is verstuurd!</span></h2>
//               Uw zakelijk internet aanvraag is succescol verstuurd. U ontvangt een passende offerte in de mailbox. Let op: dit kan 5-10 minuten duren. Check voor de zekerheid ook uw spambox. Mocht uw in tussentijd nog vragen hebben, dan kunt u altijd telefonisch contact opnemen via <a href="tel:085 48 59 380">085 48 59 380</a>.
//             `,
//         quotationNotSend: `
//               <h2>Fout bij versturen</h2>
//               Er heeft zich een fout voorgedaan. Doe de check opnieuw of neem telefonisch contact op: <a href="tel:085 48 59 380">085 48 59 380</a>. Een demo plannen kan ook, dan bekijken wij samen de mogelijkheden voor uw locatie.
//             `,
//         quotationAlreadySend: `
//               <h2>Aanvraag al verstuurd</h2>
//               Uw aanvraag is al een keer verstuurd. Doe de check opnieuw of neem telefonisch contact op: <a href="tel:085 48 59 380">085 48 59 380</a>.
//             `,
//         zipcodeNotFound: `
//               <h2>Postcode niet herkend</h2>
//               Uw postcode wordt niet herkend. Doe de check opnieuw of neem telefonisch contact op: <a href="tel:085 48 59 380">085 48 59 380</a>. Een demo plannen kan ook, dan bekijken wij samen de mogelijkheden voor uw locatie.
//             `,
//         alternativeZipcodeNotFound: `
//               <h2>Alternatieve postcode niet herkend</h2>
//               Uw postcode wordt niet herkend. Ga verder met de hoofdvestiging, doe de check opnieuw of neem telefonisch contact op: <a href="tel:085 48 59 380">085 48 59 380</a>.
//             `,
//         zipcodeNotPossible: `
//               <h2>Helaas! Op dit adres bevindt zich geen KVK-inschrijving</h2>
//               <p>Heeft u toch zakelijk internet nodig op dit adres? Dat kan:</p>
//               <ol>
//                 <li>Doe de postcodecheck opnieuw;</li>
//                 <li>Voer de postcode en het huisnummer van de hoofdvestiging in;</li>
//                 <li>Selecteer “Internet aanvragen voor een alternatief adres.</li>
//               </ol>
//               <p>Komt u er niet uit, neem dan telefonisch contact op via <a href="tel:088 612 0101">088 612 0101</a>. Of <a href="https://calendly.com/clear-mind-it-consultancy/adviesgesprek-clear-mind-it-consultancy?month=2022-11" target="_blank">vraag een demo aan</a>. Dan bekijken we in 15 minuten samen de mogelijkheden voor uw locatie.</p>
//             `,
//         companyNotFound: `
//               <h2>fout bij ophalen</h2>
//               Er heeft zich een fout voorgedaan. Doe de check opnieuw of neem telefonisch contact op: <a href="tel:085 48 59 380">085 48 59 380</a>. Een demo plannen kan ook, dan bekijken wij samen de mogelijkheden voor uw locatie.
//             `,
//         companyIsNotLegalPerson: `
//               <h2>Bedrijf is geen hoofdvestiging</h2>
//               Zakelijk internet voor uw gekozen bedrijf is niet mogelijk omdat de hoofdvestiging niet op deze locatie staat. Doe de check opnieuw of neem telefonisch contact op: <a href="tel:085 48 59 380">085 48 59 380</a>. Een demo plannen kan ook, dan bekijken wij samen de mogelijkheden voor uw locatie.
//             `,
//         companyNoLocation: `
//               <h2>Bedrijf locatie niet mogelijk</h2>
//               Zakelijk internet voor uw gekozen bedrijf is niet mogelijk vanwege de locatie. Doe de check opnieuw of neem telefonisch contact op: <a href="tel:085 48 59 380">085 48 59 380</a>. Een demo plannen kan ook, dan bekijken wij samen de mogelijkheden voor uw locatie.
//             `,
//         companyNoConnections: `
//               <h2>Bedrijf niet mogelijk</h2>
//               Zakelijk internet bestellen voor deze specifieke locatie is online niet mogelijk. Neem aub telefonisch contact op: <a href="tel:085 48 59 380">085 48 59 380</a>. Een demo plannen kan ook, dan bekijken wij samen de mogelijkheden voor uw locatie.
//             `,
//         companyDynmaicsRefused: `
//               <h2>Momenteel is een aanbieding maken niet mogelijk</h2>
//               We zouden graag de wensen persoonlijk met u willen bespreken. We zijn bereikbaar op: <a href="tel:085 48 59 380">085 48 59 380</a>. Een demo plannen kan ook, dan bekijken wij samen de mogelijkheden voor uw locatie.
//             `,
//         noIsraPoint: `
//               <h2>Aansluitpunt niet mogelijk</h2>
//               Zakelijk internet voor uw gekozen bedrijf is niet mogelijk. Doe de check opnieuw of neem telefonisch contact op: <a href="tel:085 48 59 380">085 48 59 380</a>. Een demo plannen kan ook, dan bekijken wij samen de mogelijkheden voor uw locatie.
//             `,
//         kvkNotFoundInIrma: `
//               <h2>Adres niet gevonden</h2>
//               Zakelijk internet voor uw gekozen bedrijf is niet mogelijk. Doe de check opnieuw of neem telefonisch contact op: <a href="tel:085 48 59 380">085 48 59 380</a>. Een demo plannen kan ook, dan bekijken wij samen de mogelijkheden voor uw locatie.
//             `,
//         noConnectionsWithIrma: `
//               <h2>Tijdelijk zijn checks niet mogelijk</h2>
//               Zakelijk internet aanvragen is tijdelijk niet mogelijk. Doe de check opnieuw of neem telefonisch contact op: <a href="tel:085 48 59 380">085 48 59 380</a>. Een demo plannen kan ook, dan bekijken wij samen de mogelijkheden voor uw locatie.
//             `,
//         malfunctionApi: `
//               <h2 style="font-weight: bold;">Storing</h2>
//               Om technische redenen is het op dit moment niet mogelijk de beschikbaarheid op uw locatie te controleren. Doe de check opnieuw of neem telefonisch contact op: <a href="tel:085 48 59 380">085 48 59 380</a>. Een demo plannen kan ook, dan bekijken wij samen de mogelijkheden voor uw locatie.
//             `,
//       }}
//     />
//   )
// }
