/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'
import SEO from 'components/SEO'
import Layout from 'components/shared/Layout'
import Breadcrumb from 'components/shared/Breadcrumb'
import PartnerSlider from 'components/elements/PartnerSlider'
import PostcodeCheck from '../components/Check/Order/PostcodeCheck'
// import FAQ from '../components/Check/elements/FAQ'

export const pageQuery = graphql`
  query ($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      title
      path

      yoast_meta {
        name
        content
        property
      }
    }
  }
`

const CheckTemplate = ({
  data: {
    page: { path, title, yoast_meta: yoast },
  },
  pageContext,
}) => {
  return (
    <Layout spacingBottom>
      <SEO yoast={yoast} path={path} />

      <Breadcrumb data={title} className="py-2" pageContext={pageContext} />

      <section className="container pb-5">
        <PostcodeCheck />
      </section>

      {/* <FAQ
        className="px-lg-5"
        align="left"
        title="Veelgestelde vragen"
        showMore
        questions={[
          {
            question:
              'De glasvezel ligt nu voor mijn bedrijfspand. Wie komt deze binnen aanleggen?',
            answer:
              '<p>Na het verwerken van uw bestelling zullen de gespecialiseerde aannemers van het KPN-netwerkbedrijf worden ingezet om de glasvezelverbinding in uw pand te installeren!</p>',
          },
          {
            question: 'Wat is de levertijd van de glasvezelaansluiting?',
            answer:
              "<p>De levertijd is afhankelijk van de 'fase' van het aanlegtraject. Vaak kan een glasvezelaansluiting binnen 10 dagen worden opgeleverd! Echter, in enkele gevallen kan het een half jaar tot soms meer dan één jaar duren. Dit laatste betreft vaak uitzonderingen.</p>",
          },
          {
            question:
              'Hoe zit met mijn huidige verbinding wordt deze automatisch opgezegd?',
            answer: '<p>U dient zelf uw huidige overeenkomst op te zeggen.</p>',
          },
          {
            question: 'Ik kan geen telefonie kiezen in de offerte?',
            answer:
              '<p>Correct, wij kunnen dit natuurlijk voor u leveren. We komen graag persoonlijk met u in contact om uw behoeften en wensen af te stemmen. Neem vrijblijvend contact met ons op via telefoon of stuur een e-mail naar ons. Onze adviseurs staan elke dag voor u klaar!</p>',
          },
          {
            question:
              'Werkt mijn bestaande DECT telefoon op de nieuwe verbinding?',
            answer:
              '<p>Ons advies is om deze DECT apparatuur gelijk te vervangen.</p>',
          },
        ]}
      /> */}

      <section className="container mb-5 pb-5">
        <div className="py-5 row">
          <div className="col-12 col-lg-7 col-xl-8">
            <PartnerSlider initialSlides={3} />
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default CheckTemplate
